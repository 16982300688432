@media (max-width: 767px) {
  .hero .container .hero-inner .hero-content {
    width: 100% !important; /* Full width for mobile */
  }

  .btn-center-container {
    display: flex;
    justify-content: center;
    padding: 30px 0px;
    align-items: center;
  }

  .font-styling-lg {
    display: none !important;
  }

  .hero .container .hero-inner {
    flex-direction: column-reverse !important;
  }

  .font-styling {
    font-size: 18px !important;
    margin-bottom: 0px !important;
  }

  .hero
    .container
    .hero-inner
    .hero-content
    .hero-content-inner
    h1
    .hero-content-line {
    height: auto !important;
  }

  .hero .container .hero-inner {
    margin: 0px !important;
  }

  .hero
    .container
    .hero-inner
    .hero-images
    .hero-images-inner
    .hero-image.girl {
    right: -56% !important;
    width: 100% !important;
    height: 100% !important;
  }

  .hero .container .hero-inner .hero-images .hero-images-inner .hero-image.boy {
    left: -50% !important;
    width: 100% !important;
    height: 100% !important;
  }

  .hero-content-line > div {
    text-align: center !important;
  }

  .hero .container .hero-inner .hero-content .hero-content-inner p {
    padding-right: 0px !important;
    margin-bottom: 0px !important;
  }

  .btn-show {
    background: none;
    border: none;
    color: blue; /* or any other link color you prefer */
    text-decoration: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }
}

/* Tablet view (768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .hero .container .hero-inner .hero-content {
    width: 100% !important; /* Full width for tablet */
  }
}

@media (max-width: 767px) {
  .hero-content-inner {
    flex-direction: column-reverse; /* Reverse the order of elements for mobile */
  }

  .mobile-image {
    text-align: center; /* Center the image in mobile view */
    margin-bottom: 10px; /* Add some margin below the image */
  }

  .mobile-image img {
    width: 200px; /* Adjust the width as per your design */
    height: auto; /* Maintain the aspect ratio */
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.thumbnail-slider-wrap {
  margin-top: 15px !important;
  height: 85px !important;
}
.thumbnail-slider-wrap .slick-track .slick-slide {
  text-align: center !important;
}
.thumbnail-slider-wrap .slick-track .slick-slide img {
  width: 70% !important;
}

.stepper-connector {
  border: 1px dashed #000;
  width: 10%;
  margin: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  position: absolute;
  overflow-x: auto;
}

.element::after {
  content: "" !important;
  width: 365px !important;
  height: 1.5px !important;
  background-color: #e36000 !important;
  position: absolute !important;
  top: calc(50% - 1px) !important;
  z-index: 0;
}
