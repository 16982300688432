
@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-radio-checked .ant-radio-inner{
  border-color: orange !important;
}

.ant-radio-inner::after{
  background-color: orange !important;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .stepper{
    transform: rotate(90deg);
  }  
}

.ant-checkbox-checked::after{
  border: 1px solid orange !important;
}


.ant-checkbox-checked .ant-checkbox-inner{
  background-color:  orange !important;
  border-color: orange !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
  border-color: orange !important;
}

.moib{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  font-family: futura-book;
  position: relative;
  margin-right: 22px;
  white-space: nowrap;
}

i{
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  width: 8px;
  border-radius: 2px;
  height: 3px;
  background: #e4e5e8;
  margin-top: 12px;
}

.ant-slider:hover .ant-slider-track{
  background-color: orange !important;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open){
  border-color: orange !important;
}

.ant-slider-handle{
  border: solid 2px orange !important;
}

.ant-slider-track{
  background-color: orange !important;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.-z-1 {
  z-index: -1;
}

.origin-0 {
  transform-origin: 0%;
}

input:focus ~ label,
input:not(:placeholder-shown) ~ label,
textarea:focus ~ label,
textarea:not(:placeholder-shown) ~ label,
select:focus ~ label,
select:not([value='']):valid ~ label {
  /* @apply transform; scale-75; -translate-y-6; */
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-scale-x: 0.75;
  --tw-scale-y: 0.75;
  --tw-translate-y: -1.5rem;
}

input:focus ~ label,
select:focus ~ label {
  /* @apply text-black; left-0; */
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  left: 0px;
}

.styles__inputWrap {
  border-radius: 8px;
  font-family: futura-demi;
  position: relative;
  width: 100%;
  color: #2e054e;
}

.styles__inputWrap .styles__auxilaryInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  background: #f2f3f5;
  height: 50px;
  outline: 0;
  border: 1px solid #f2f3f5;
  border-radius: 6px;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #2e054e;
  padding-left: 16px;
  box-shadow: none;
  font-family: futura-demi;
}

.styles__inputWrap .styles__inputLabel {
  width: 1px;
  height: 1px;
  overflow: hidden;
  display: block;
  margin: -1px;
}

.styles__inputWrap .styles__inputLabel:before {
  content: attr(data-value);
  position: absolute;
  left: 16px;
  top: 14px;
  pointer-events: none;
  transition: all .2s ease;
  color: #acacac;
  font-family: futura-book;
  font-size: 14px;
  line-height: 20px;
}

.box{
  padding-right: 1.5rem !important;
  margin: 0px auto !important;
}

.carousel-image{
  border-radius: 10px !important;
}

.css-1l48fx1>*:not(style)~*:not(style){
  margin-top: 0rem !important;
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    .box{
      padding-right: 0px !important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
}


